<template>
	<div class="p-3 liff-reservation-success">

    <div class="liff-reservation-success__title">預約成功！</div>
    <div class="liff-reservation-success__subtitle">您的預約資訊如下，恭候您的光臨！</div>

		<b-card header-tag="header" class="mb-3 liff-reservation-success__card">
			<template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h6 class="mb-0">預約人資訊</h6>
        </div>
      </template>
			<b-form-group label="姓名" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
         <div class="text-right s-text-sm">{{ form.name }}</div>
			</b-form-group>
			<b-form-group label="電話" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm">
        <div class="text-right s-text-sm">{{ form.phone }}</div>
			</b-form-group>
		</b-card>

		<b-card header-tag="header" class="mb-3 liff-reservation-success__card">
			<template #header>
				<div class="d-flex justify-content-between align-items-center">
					<h6 class="mb-0">預約資訊</h6>
				</div>
			</template>
			<b-form-group label="項目名稱" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
        <div class="text-right s-text-sm">{{ form.serviceName }}</div>

			</b-form-group>
			<b-form-group label="服務人員" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
        <div class="text-right s-text-sm">{{ form.servicePerson }}</div>

			</b-form-group>
			<b-form-group label="預約時間" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
        <div class="text-right s-text-sm">{{ form.serviceTime }}</div>

			</b-form-group>
			<b-form-group label="服務地點" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
        <div class="text-right s-text-sm">{{ form.serviceLocation }}</div>
			</b-form-group>
      <b-form-group label="備註" label-cols="4" label-cols-lg="2" label-size="sm" label-for="input-sm" class="mb-3">
        <div class="text-right s-text-sm">{{ form.note }}</div>
			</b-form-group>
		</b-card>

		<div class="d-flex">
			<SharedButton class="s-btn-outline-primary" @click="prevStep">返回</SharedButton>
		</div>
	</div>
</template>

<script>
import SharedButton from '@/components/Page/Liff/Shared/Button.vue';

	export default {
		components: {
			SharedButton
		},
		data() {
			return {
				form: {
					name: '林曉娟',
					phone: '0987654321',
					serviceName: '精緻剪髮、歐系染髮、洗髮',
					servicePerson: 'Nymphia',
					serviceTime: '2024/01/01 09:00',
					serviceLocation: '台北市中山區中山北路二段36巷36號',
					note: '我幫我媽預約，媽媽姓名王美花',
				}
			}
		},

    methods: {
      prevStep() {
        this.$router.push({
          name: 'LiffReservationConfirm'
        })
      },
    }
	}

</script> 

<style lang="scss" scoped>
.liff-reservation-success {
  &__title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 12px;
  }

  &__subtitle {
    font-size: 15px;
    text-align: center;
    margin-bottom: 16px;
  }

	&__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		.card-header {
			border-radius: 10px 10px 0 0;
			border-bottom: 1px solid #E5E5EA;
			font-size: 16px;
			font-weight: 500;
			padding: 16px 16px 12px 16px;
		}
	}
	&__textarea {
		border-radius: 10px;
		border: 1px solid #636366;
		color: #2C2C2E;
		font-size: 16px;
		line-height: 1.2;
		padding: 16px 12px;
	}

	input {
		font-size: 14px;
		height: auto;
    line-height: 1.2;
		border-bottom: 1px solid #E5E5EA;
    padding-bottom: 6px;
	}
}
</style>